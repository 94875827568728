export function getCookie(cname) {
    if (typeof window !== "undefined") {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}

export function setCookie(cname, cvalue, exdays) {
    const domain = getDomainFromUrl(document.location.href);

    if (typeof window !== "undefined") {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/; domain=" + domain;
    }
}

export function createHrefUrl(path, params) {
    const parameters = new URLSearchParams(params);
    const str = parameters.toString();
    if (str !== "") {
        return path + "?" + str;
    } else {
        return path;
    }
}

export function getAllQueryParamsFromString(search) {
    const params = new URLSearchParams(search);
    let paramObj = {};
    for(let value of params.keys()) {
        paramObj[value] = params.get(value);
    }
    return paramObj;
}

export function getWindowSearchParams() {
    if (typeof window !== "undefined") {
        return window.location.search;
    } else {
        return '';
    }
}

export function getPixelScript() {
    return`!function (w, d, t) {
             w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
             ttq.load('${process.env.TIKTOK_PIXEL_KEY}');
             ttq.page();
          }(window, document, 'ttq');`
}

function getDomainFromUrl(url) {
    const urlObj = new URL(url);
    return urlObj.hostname;
}

export default {getCookie, setCookie, createHrefUrl, getAllQueryParamsFromString, getWindowSearchParams, getPixelScript};